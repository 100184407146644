"use strict";

(function (window, document, undefined) {

	/*
	 * Tab
	 */

	if(typeof(document.querySelector('.js-tab_item')) != 'undefined' && document.querySelector('.js-tab_item') != null) {
	    document.querySelectorAll('.js-tab_item').forEach(el => {
	        el.addEventListener('click', event => {
	            let elSiblings = elemSiblings(el);
	            elSiblings.forEach(sibling => sibling.classList.remove('is-current'));
	            el.classList.add('is-current');
	        });
	    });
	}


	/*
	 * elemSiblings
	 */

	const elemSiblings = n => [...n.parentElement.children].filter(c=>c!=n);

})(window, document);
