"use strict";

import Swiper from '../vendor/swiper';

(function (window, document, undefined) {

	// Fix NodeList.forEach in IE11
	if (window.NodeList && !NodeList.prototype.forEach) {
		NodeList.prototype.forEach = Array.prototype.forEach;
	}

	// Inits
	document.querySelectorAll('.js-swiper').forEach(swiper => {
	    let swiperID = swiper.id;
		let params = swiper.dataset.swiper;
		let swiperType = '';

		if (swiperID.includes('brands-slider')) {
			swiperType = 'brands-slider';
		} else if (swiperID.includes('brands-tractor-slider')) {
			swiperType = 'brands-tractor-slider';
		} else if (swiperID.includes('brands-tyre-slider')) {
			swiperType = 'brands-tyre-slider';
		} else {
			swiperType = swiperID;
		}

		if (params) {
			params = JSON.parse(swiper.dataset.swiper);
		} else {
			switch (swiperType) {
				case 'brands-slider':
					params = {
						autoHeight : true,
						slidesPerView : 1,
						allowTouchMove : false
					}
					break;
				case 'brands-tractor-slider':
				case 'brands-tyre-slider':
					params = {
						autoHeight : false,
						slidesPerView : 2,
						spaceBetween : 24,
						nested : true,
						navigation : {
							prevEl : '#' + swiperID + '_prev',
							nextEl : '#' + swiperID + '_next'
						},
						breakpoints : {
							769 : {
								slidesPerView : 3,
								spaceBetween : 48
							},
							1025 : {
								slidesPerView : 6,
								spaceBetween : 48
							}
						}
					}
					break;
				case 'product-related-slider':
					params = {
						slidesPerView : 1,
						allowTouchMove : false
					}
					break;
				case 'other-products-related-slider':
				case 'similar-products-related-slider':
					params = {
						centeredSlides : true,
						slidesPerView : 'auto',
						effect : 'coverflow',
						coverflowEffect : {
							slideShadows : false,
							rotate : 0,
							stretch : -12,
							depth : 0
						},
						nested : true,
						breakpoints : {
							769 : {
								centeredSlides : false,
								slidesPerView : 3,
								spaceBetween : 12,
								effect : 'slide'
							},
							1025 : {
								centeredSlides : false,
								slidesPerView : 4,
								spaceBetween : 12,
								effect : 'slide'
							}
						}
					}
					break;
			}
		}

	    let mySwiper = new Swiper(swiper, params);

	    // Swiper Height
	    document.addEventListener('lazyloaded', function (e) {
	        if(swiper.dataset.autoheight)
	            mySwiper.updateAutoHeight();
	    });
	});

	// Swiper GOTO
	document.querySelectorAll('.js-swiper_goto').forEach(goto => {
	    goto.addEventListener('click', event => {
	        event.currentTarget.blur();

	        let swiperSlider = JSON.parse(goto.dataset.slider);
	        let swiperSlide = goto.dataset.slide;

	        for (var i = 0; i < swiperSlider.length; i++) {
	            let mySwiper = document.getElementById(swiperSlider[i]).swiper;
	            mySwiper.slideTo(swiperSlide);
	        }
	    });
	});

	// Swiper Product Gallery / Thumbs
	if(typeof(document.querySelector('.product-gallery')) != 'undefined' && document.querySelector('.product-gallery') != null) {
		document.querySelectorAll('.product-gallery').forEach(gallery => {
			let productId = gallery.dataset.productId;

			let productGallery = new Swiper('#product-gallery-' + productId, {
				navigation : {
					prevEl : '#product-gallery_prev-' + productId,
					nextEl : '#product-gallery_next-' + productId,
				},
				loop : true,
				loopedSlides : 4
			});

			let productThumbs = new Swiper('#product-thumbs-' + productId, {
				spaceBetween : 12,
				centeredSlides : true,
				slidesPerView : 3,
				touchRatio : 0.2,
				slideToClickedSlide : true,
				loop : true,
				loopedSlides : 4
			});

			productGallery.controller.control = productThumbs;
			productThumbs.controller.control = productGallery;
		});
	}

})(window, document);
