'use strict';

export default class FlashMessage {
	constructor() {
		this.listeners();
	}

	listeners() {
		document.addEventListener('click', this.closeFlashMessage, false);
	}

	closeFlashMessage(el) {
		var target = el.target;

		if (el.isTrusted) {
			for (var appTarget = el.target; appTarget && appTarget != this; appTarget = appTarget.parentNode) {
				if (appTarget.matches('.js-flash-message_close')) {
					target = appTarget;
				}
			}
		}

		if (target.matches('.js-flash-message_close')) {
			let hideMessages = true;

			target.blur();
			target.parentNode.classList.add('is-dismissed');

			document.querySelectorAll('.js-flash-messages .js-flash-message:not(.js-alert-template)').forEach(el => {
				if(!el.classList.contains('is-dismissed'))
					hideMessages = false;
			});

			if(hideMessages) {
				setTimeout(function() {
					document.querySelector('.js-flash-messages').classList.add('is-all-dismissed');
				}, 600);
			}
		}
	}
}
